import type {OnyxUpdate} from 'react-native-onyx';
import Onyx from 'react-native-onyx';
import * as API from '@libs/API';
import {MedicationParam} from '@libs/API/parameters';
import {WRITE_COMMANDS} from '@libs/API/types';
import Navigation from '@libs/Navigation/Navigation';
import * as ReportUtils from '@libs/ReportUtils';
import ONYXKEYS from '@src/ONYXKEYS';
import type {Route} from '@src/ROUTES';
import ROUTES from '@src/ROUTES';
import type {MedicationDetails} from '@src/types/onyx';
import type {Receipt} from '@src/types/onyx/Transaction';
// import {getMedicationDefaultValues} from './medication';

async function saveMedication(medparam: MedicationDetails, receipt: Receipt | undefined) {
    // Optimistic update to set loading state

    // Convert response to a blob

    // const defaultMedication = getMedicationDefaultValues();
    console.log('in save medication, medparam is', medparam);


    const x = {
        ...medparam,
        recordID: medparam?.recordID,
        document: receipt,
    };

    const optimisticData: OnyxUpdate[] = [
        {
            onyxMethod: Onyx.METHOD.MERGE,
            key: `${ONYXKEYS.COLLECTION.MEDICATION}${x.recordID}`,

            value: {
                ...x,
            },
        },
    ];

    // Successful update, clearing loading state and updating with server response
    const successData: OnyxUpdate[] = [
        {
            onyxMethod: Onyx.METHOD.MERGE,
            key: `${ONYXKEYS.COLLECTION.MEDICATION}${x.recordID}`,
            // REMOVE  the optimistic one for preventing duplication and set the new one
            value: {},
        },
        {
            onyxMethod: Onyx.METHOD.SET,
            key: `${ONYXKEYS.MEDICATION_DETAILS}`,
            // REMOVE  the optimistic one for preventing duplication and set the new one
            value: {},
        },
    ];

    // Failure update, clearing loading state and marking the operation as failed
    const failureData: OnyxUpdate[] = [
        {
            onyxMethod: Onyx.METHOD.MERGE,
            key: `${ONYXKEYS.COLLECTION.MEDICATION}${x.recordID}`,
            value: {},
        },
    ];


    if (receipt && !medparam?.medicationName) {
        //This means User scanned and we process in the backend. 

        console.info('Document Scanned', medparam);

        const y = {
            recordID: medparam?.recordID,
            reportID: medparam?.reportID,
            documentType: "medication",
            document: receipt,

        };

        API.write(WRITE_COMMANDS.SCAN_DOCUMENT, y, {
            optimisticData,
            successData,
            failureData,
        });
        
    } else {
        // API call with write parameters
        console.info('Medication TRACK', medparam);

        API.write(WRITE_COMMANDS.TRACK_MEDICATION, x, {
            optimisticData,
            successData,
            failureData,
        });
    }

    const activeReportID = medparam.reportID?.[0] ?? '';
    if (activeReportID) {
        Navigation.dismissModal(activeReportID); 
        Navigation.navigate(ROUTES.REPORT_WITH_ID.getRoute(activeReportID));
    } else {
        Navigation.navigate(ROUTES.SEARCH_CENTRAL_PANE.route as Route);
    }

    
}
export {saveMedication};
