import React from 'react';
import BaseListItem from '@components/SelectionList/BaseListItem';
import type {ListItem, MedicationListItemtype, TransactionListItemProps} from '@components/SelectionList/types';
import useAnimatedHighlightStyle from '@hooks/useAnimatedHighlightStyle';
import useResponsiveLayout from '@hooks/useResponsiveLayout';
import useTheme from '@hooks/useTheme';
import useThemeStyles from '@hooks/useThemeStyles';
import variables from '@styles/variables';
import MedicationListItemRow from './MedicationListItemRow';

function MedicationListItem<TItem extends ListItem>({item, isFocused, isDisabled, onSelectRow, onDismissError, onFocus, onLongPressRow, shouldSyncFocus}: TransactionListItemProps<TItem>) {
    const medicationItem = item as unknown as MedicationListItemtype;
    const styles = useThemeStyles();
    const theme = useTheme();

    const {isLargeScreenWidth} = useResponsiveLayout();

    const listItemPressableStyle = [
        styles.selectionListPressableItemWrapper,
        styles.pv3,
        styles.ph3,
        // Removing background style because they are added to the parent OpacityView via animatedHighlightStyle
        styles.bgTransparent,
        medicationItem?.isSelected && styles.activeComponentBG,
        isFocused && styles.sidebarLinkActive,
        styles.mh0,
    ];

    const listItemWrapperStyle = [
        styles.flex1,
        styles.userSelectNone,
        isLargeScreenWidth ? {...styles.flexRow, ...styles.justifyContentBetween, ...styles.alignItemsCenter} : {...styles.flexColumn, ...styles.alignItemsStretch},
    ];

    const animatedHighlightStyle = useAnimatedHighlightStyle({
        borderRadius: variables.componentBorderRadius,
        shouldHighlight: medicationItem?.shouldAnimateInHighlight ?? false,
        highlightColor: theme.messageHighlightBG,
        backgroundColor: theme.highlightBG,
    });

    return (
        <BaseListItem
            item={item}
            pressableStyle={listItemPressableStyle}
            wrapperStyle={listItemWrapperStyle}
            containerStyle={[styles.mb2]}
            isFocused={isFocused}
            isDisabled={isDisabled}
            showTooltip={false}
            onSelectRow={onSelectRow}
            onDismissError={onDismissError}
            errors={item?.errors}
            pendingAction={item?.pendingAction}
            keyForList={item?.keyForList}
            onFocus={onFocus}
            onLongPressRow={onLongPressRow}
            shouldSyncFocus={shouldSyncFocus}
            hoverStyle={item.isSelected && styles.activeComponentBG}
            pressableWrapperStyle={[styles.mh5, animatedHighlightStyle]}
        >
            <MedicationListItemRow item={medicationItem} />
        </BaseListItem>
    );
}

MedicationListItem.displayName = 'MedicationListItem';

export default MedicationListItem;
