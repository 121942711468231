import Onyx from 'react-native-onyx';
import ONYXKEYS from '@src/ONYXKEYS';
import {saveAppointment} from './TrackAppointment';
import {saveDocument} from './TrackDocument';
import {saveMedication} from './TrackMedication';
import CONST from '@src/CONST';
import {rand64} from '@libs/NumberUtils';
import type {OptimisticCreatedReportAction} from '@libs/ReportUtils';
import DateUtils from '@libs/DateUtils';
import type {NullishDeep, OnyxCollection, OnyxEntry, OnyxInputValue, OnyxUpdate} from 'react-native-onyx';
import type * as OnyxTypes from '@src/types/onyx';

import {isEmptyObject} from '@src/types/utils/EmptyObject';

import * as ReportUtils from '@libs/ReportUtils';
import * as API from '@libs/API';
import {WRITE_COMMANDS} from '@libs/API/types';
import type {Receipt} from '@src/types/onyx/Transaction';
import { clearMedicationDetails, getMedicationDetailsDefaultValues} from '@libs/actions/medicationDetails';
import { clearAppointmentDetails, getAppointmentDetailsDefaultValues} from '@libs/actions/appointmentDetails';
import Navigation from '@libs/Navigation/Navigation';
import ROUTES from '@src/ROUTES';


import type {
    AddDoctorParams,
    DoctorParams,
    OpenMedicationParams,
    TrackAppointmentParams,
    TrackDocumentParams,
} from '@libs/API/parameters';



type IOURequestType = ValueOf<typeof CONST.IOU.REQUEST_TYPE>;


import type {ValueOf} from 'type-fest';

import {HealthRecordRequestType, HealthRecordRequestData} from '@src/types/onyx/HealthRecords';

import type {
    UpdateMedicationDocumentParams,
    DeleteMedicationParams,
} from '@libs/API/parameters';
import ConsolePage from '@pages/settings/AboutPage/ConsolePage';

let allTransactions: NonNullable<OnyxCollection<OnyxTypes.Transaction>> = {};
Onyx.connect({
    key: ONYXKEYS.COLLECTION.TRANSACTION,
    waitForCollectionCallback: true,
    callback: (value) => {
        if (!value) {
            allTransactions = {};
            return;
        }

        allTransactions = value;
    },
});



function startHealthRecordRequest(iouType: ValueOf<typeof CONST.IOU.TYPE>, reportID: string, requestType?: IOURequestType, skipConfirmation = false, transactionID?: string) {
    

    //Function to clear health record information from the ONYX
    clearHealthRecordRequest();

    // clearMoneyRequest(CONST.IOU.OPTIMISTIC_TRANSACTION_ID, skipConfirmation);
    switch (requestType) {
        case CONST.IOU.REQUEST_TYPE.MANUAL:
            Navigation.navigate(ROUTES.MONEY_REQUEST_CREATE_TAB_MANUAL.getRoute(CONST.IOU.ACTION.CREATE, iouType, CONST.IOU.OPTIMISTIC_TRANSACTION_ID, reportID));
            return;
        case CONST.IOU.REQUEST_TYPE.SCAN:
            Navigation.navigate(ROUTES.MONEY_REQUEST_CREATE_TAB_SCAN.getRoute(CONST.IOU.ACTION.CREATE, iouType, CONST.IOU.OPTIMISTIC_TRANSACTION_ID, reportID));
            return;
        default:
            Navigation.navigate(ROUTES.MONEY_REQUEST_CREATE.getRoute(CONST.IOU.ACTION.CREATE, iouType, transactionID ?? CONST.IOU.OPTIMISTIC_TRANSACTION_ID, reportID));
    }
}



/**
 * Build optimistic data for medical requests
 */
function buildOnyxDataForHealthRecordRequest(
    type: HealthRecordRequestType,
    data: HealthRecordRequestData,
): [OnyxUpdate[], OnyxUpdate[]] {
    const optimisticData: OnyxUpdate[] = [];
    const failureData: OnyxUpdate[] = [];


    // const shouldSaveReportActions = type === 'medication';
    console.log ("Data is ", data)
    let reportID;
    let recordID;

    // const reportID = data.medication?.reportID || data.appointment?.reportID || data.document?.reportID;
    console.log('Building Onyx data for:', type);

    const collectionKey = (() => {
        switch (type) {
            case 'medication':
                console.log('Processing medication data');
                reportID = data.medication?.reportID;
                recordID = data.medication?.recordID;
                return ONYXKEYS.COLLECTION.MEDICATION;
            case 'appointment':
                console.log('Processing appointment data');
                reportID = data.appointment?.reportID;
                recordID = data.appointment?.recordID;
                return ONYXKEYS.COLLECTION.APPOINTMENT;
            case 'document':
                console.log('Processing document data');
                reportID = data.document?.reportID;
                recordID = data.document?.recordID;
                return ONYXKEYS.COLLECTION.DOCUMENT;
            default:
                console.log('Unknown type:', type);
                return null;
        }
    })();

    if (!collectionKey) {
        console.error('Invalid type:', type);
        return [optimisticData, failureData];
    }

    // Prepare the base value
    const baseValue = data[type] || {};
    
    // Extract only serializable properties from receipt file if present
    const value = data.receiptFile 
        ? {
            ...baseValue,
            document: {
                source: data.receiptFile.source,
                state: "SCANNING",
                // uri: data.receiptFile.uri,
                title: data.receiptFile.name,
                // lastModified: data.receiptFile.lastModified,
            },
        }
        : baseValue;

    console.log('Prepared value:', value);

    // Add optimistic data for the medical request
    optimisticData.push({
        onyxMethod: Onyx.METHOD.SET,
        key: `${collectionKey}${recordID}`,
        value,
    });

    if (reportID) {
        // Create and add optimistic report action
        const reportAction = ReportUtils.buildOptimisticHealthRecordAction(type, data);


        optimisticData.push({
            onyxMethod: Onyx.METHOD.MERGE,
        key: `${ONYXKEYS.COLLECTION.REPORT_ACTIONS}${reportID}`,
        value: {[reportAction.reportActionID]: reportAction},
    });

    // Add failure data to revert changes if needed
    failureData.push(
        {
            onyxMethod: Onyx.METHOD.SET,
            key: `${collectionKey}${data.medicationRequestDataID}`,
            value: null,
        },
        {
            onyxMethod: Onyx.METHOD.MERGE,
            key: `${ONYXKEYS.COLLECTION.REPORT_ACTIONS}${reportID}`,
            value: {[reportAction.reportActionID]: null},
        }
       
    );
    }
    return [optimisticData, failureData];
}




//Function to clear health record information from the ONYX
function clearHealthRecordRequest() {

    clearMedicationDetails();
    clearAppointmentDetails();

    // setMediationDetailsDefaultReport();
    // setMedicationDetailsDefaultValues();

    console.log("[IOU - CLEARED HEALTH RECORD] Clear health record information from the ONYX");

}


function initHealthRecordRequest (iouType: ValueOf<typeof CONST.IOU.TYPE>) {


    switch (iouType) {
        case CONST.IOU.TYPE.MEDICATION:
            getMedicationDetailsDefaultValues();
            break;
        case CONST.IOU.TYPE.APPOINTMENT:
            getAppointmentDetailsDefaultValues();
            break;
        case CONST.IOU.TYPE.DOCUMENT:
            console.log("[DOCUMENT]Clear health record information from the ONYX");
            // getDocumentDetailsDefaultValues();
            break;

        default:
            break;
    }

}


/**
 * Save medical request with optimistic updates
 */
function saveHealthRecordRequest(type: HealthRecordRequestType, data: HealthRecordRequestData) {
    console.log('Saving medical request:', type);

    // Build optimistic data
    const [optimisticData, failureData] = buildOnyxDataForHealthRecordRequest(type, data);

    console.log('Optimistic data is', optimisticData);
    console.log('Failure data is', failureData);

    // Apply optimistic updates and handle success/failure
    return Onyx.update(optimisticData)
        .then(() => {
            console.log('Successfully updated Onyx with optimistic data');
            
            
            // Call appropriate save function
            let savePromise;
            switch (type) {
                case 'appointment':
                    savePromise = saveAppointment(data.appointment, data.receiptFile);
                    break;
                case 'document':
                    savePromise = saveDocument(data.document, data.receiptFile);
                    break;
                case 'medication':
                    savePromise = saveMedication(data.medication, data.receiptFile);
                    break;
                default:
                    return Promise.reject(new Error(`Unknown medical request type: ${type}`));
            }

            // Handle API response
            return savePromise
                .then(() => {
                    console.log('Successfully saved medical request to API');
                })
                .catch((error) => {
                    console.error('Error saving medical request:', error);
                    // Revert optimistic updates on failure
                    return Onyx.update(failureData).then(() => {
                        throw error; // Re-throw the error after reverting
                    });
                });
        })
        .catch((error) => {
            console.error('Failed to update Onyx with optimistic data:', error);
            throw error;
        });
}



/** Updates the description of an expense */
function updateMedicationNote(transactionID: string, transactionThreadReportID: string, comment: string) {
    const optimisticData: OnyxUpdate[] = [];

    optimisticData.push({
        onyxMethod: Onyx.METHOD.MERGE,
        key: `${ONYXKEYS.COLLECTION.MEDICATION}${transactionID}`,
        value: {medicationNote: comment},
    });

    // const transactionChanges: TransactionChanges = {
    //     comment,
    // };
    // const allReports = ReportConnection.getAllReports();
    // const transactionThreadReport = allReports?.[`${ONYXKEYS.COLLECTION.REPORT}${transactionThreadReportID}`] ?? null;
    // const parentReport = allReports?.[`${ONYXKEYS.COLLECTION.REPORT}${transactionThreadReport?.parentReportID}`] ?? null;
    // let data: UpdateMoneyRequestData;
    // if (ReportUtils.isTrackExpenseReport(transactionThreadReport) && ReportUtils.isSelfDM(parentReport)) {
    //     data = getUpdateTrackExpenseParams(transactionID, transactionThreadReportID, transactionChanges, true, policy);
    // } else {
    // data = getUpdateMoneyRequestParams(transactionID, transactionThreadReportID, transactionChanges, policy, policyTagList, policyCategories, true);
    // }
    // const {params, onyxData} = data;
    const onyxData = {optimisticData};
    API.write(WRITE_COMMANDS.UPDATE_MEDICATION_NOTE, {recordID: transactionID, medicationNote: comment}, onyxData);
}
function updateMedicationName(transactionID: string, medicationName: string) {
    const optimisticData: OnyxUpdate[] = [];

    optimisticData.push({
        onyxMethod: Onyx.METHOD.MERGE,
        key: `${ONYXKEYS.COLLECTION.MEDICATION}${transactionID}`,
        value: {medicationName},
    });

    const onyxData = {optimisticData};
    API.write(WRITE_COMMANDS.UPDATE_MEDICATION_NAME, {recordID: transactionID, medicationName}, onyxData);
}

function updateMedicationDate(transactionID: string, medicationStartDate: string, medicationEndDate: string) {
    const optimisticData: OnyxUpdate[] = [];

    optimisticData.push({
        onyxMethod: Onyx.METHOD.MERGE,
        key: `${ONYXKEYS.COLLECTION.MEDICATION}${transactionID}`,
        value: {medicationStartDate, medicationEndDate},
    });

    const onyxData = {optimisticData};
    API.write(WRITE_COMMANDS.UPDATE_MEDICATION_DATE, {recordID: transactionID, medicationStartDate, medicationEndDate}, onyxData);
}

function updateMedicationChatRoom(
    transactionID: string,
    medicationReportName: string,

    reportID?: string,
) {
    const optimisticData: OnyxUpdate[] = [];

    optimisticData.push({
        onyxMethod: Onyx.METHOD.MERGE,
        key: `${ONYXKEYS.COLLECTION.MEDICATION}${transactionID}`,
        value: {reportName: medicationReportName},
    });

    const onyxData = {optimisticData};
    API.write(WRITE_COMMANDS.UPDATE_MEDICATION_CHAT, {recordID: transactionID, reportID: reportID ?? '', reportName: medicationReportName}, onyxData);
}

function updateMedicationDocument(transactionID: string, document: Receipt) {
    const optimisticData: OnyxUpdate[] = [];

    optimisticData.push({
        onyxMethod: Onyx.METHOD.MERGE,
        key: `${ONYXKEYS.COLLECTION.MEDICATION}${transactionID}`,
        value: {document},
    });

    const onyxData = {optimisticData};
    API.write(WRITE_COMMANDS.UPDATE_MEDICATION_DOCUMENT, {recordID: transactionID, document}, onyxData);
}

function updateMedicationReminderOn(transactionID: string, reminder: boolean) {
    const optimisticData: OnyxUpdate[] = [];

    optimisticData.push({
        onyxMethod: Onyx.METHOD.MERGE,
        key: `${ONYXKEYS.COLLECTION.MEDICATION}${transactionID}`,
        value: {medicationReminderOn: reminder},
    });

    const onyxData = {optimisticData};
    API.write(WRITE_COMMANDS.UPDATE_MEDICATION_REMINDER, {recordID: transactionID, medicationReminderOn: reminder}, onyxData);
}

function updateMedicationFrequency(transactionID: string, medicationTime: string, medicationFrequency: string) {
    const optimisticData: OnyxUpdate[] = [];

    optimisticData.push({
        onyxMethod: Onyx.METHOD.MERGE,
        key: `${ONYXKEYS.COLLECTION.MEDICATION}${transactionID}`,
        value: {medicationTime, medicationFrequency},
    });

    const onyxData = {optimisticData};
    API.write(WRITE_COMMANDS.UPDATE_MEDICATION_FREQUENCY, {recordID: transactionID, medicationTime, medicationFrequency}, onyxData);
}

function updateMedicationDosage(transactionID: string, medicationDose?: string, medicationUnit?: string, medicationForm?: string) {
    const optimisticData: OnyxUpdate[] = [];

    optimisticData.push({
        onyxMethod: Onyx.METHOD.MERGE,
        key: `${ONYXKEYS.COLLECTION.MEDICATION}${transactionID}`,
        value: {medicationDose, medicationUnit, medicationForm},
    });

    const onyxData = {optimisticData};
    API.write(WRITE_COMMANDS.UPDATE_MEDICATION_DOSAGE, {recordID: transactionID, medicationDose, medicationUnit, medicationForm}, onyxData);
}


function replaceMedicationImage(transactionID: string, file: File, source: string) {
    const transaction = allTransactions[`${ONYXKEYS.COLLECTION.MEDICATION}${transactionID}`];
    const oldReceipt = transaction?.receipt ?? {};
    const receiptOptimistic = {
        source,
        state: CONST.IOU.RECEIPT_STATE.OPEN,
    };

    const optimisticData: OnyxUpdate[] = [
        {
            onyxMethod: Onyx.METHOD.MERGE,
            key: `${ONYXKEYS.COLLECTION.MEDICATION}${transactionID}`,
            value: {
                document: receiptOptimistic,
                filename: file.name,
            },
        },
    ];

    const successData: OnyxUpdate[] = [
        {
            onyxMethod: Onyx.METHOD.MERGE,
            key: `${ONYXKEYS.COLLECTION.MEDICATION}${transactionID}`,
            value: {},
        },
    ];

    const failureData: OnyxUpdate[] = [
        {
            onyxMethod: Onyx.METHOD.MERGE,
            key: `${ONYXKEYS.COLLECTION.MEDICATION}${transactionID}`,
            value: {
                document: !isEmptyObject(oldReceipt) ? oldReceipt : null,
                filename: transaction?.filename,
                // errors: getReceiptError(receiptOptimistic, file.name),
                // pendingFields: {
                //     receipt: null,
                // },
            },
        },
    ];

    const parameters: UpdateMedicationDocumentParams = {
        recordID: transactionID,
        document: file,
        documentState: 'OPEN',
    };

    API.write(WRITE_COMMANDS.UPDATE_MEDICATION_DOCUMENT, parameters, {optimisticData, successData, failureData});
}

function deleteMedication(recordID: string) {
    const parameters: DeleteMedicationParams = {recordID};

    const optimisticData: OnyxUpdate[] = [];

    optimisticData.push({
        onyxMethod: Onyx.METHOD.SET,
        key: `${ONYXKEYS.COLLECTION.MEDICATION}${recordID}`,
        value: null,
    });

    // STEP 6: Make the API request
    API.write(WRITE_COMMANDS.DELETE_MEDICATION, parameters, {optimisticData});
}


function openMedication(recordID: string) {
    if (!recordID) {
        return;
    }

    const optimisticData: OnyxUpdate[] = [
        {
            onyxMethod: Onyx.METHOD.MERGE,
            key: `${ONYXKEYS.COLLECTION.MEDICATION_METADATA}${recordID}`,
            value: {
                isLoadingInitialReportActions: true,
            },
        },
    ];

    const successData: OnyxUpdate[] = [
        {
            onyxMethod: Onyx.METHOD.MERGE,
            key: `${ONYXKEYS.COLLECTION.MEDICATION}${recordID}`,
            value: {
                // TODO check this value if don't get data
            },
        },
        {
            onyxMethod: Onyx.METHOD.MERGE,
            key: `${ONYXKEYS.COLLECTION.MEDICATION_METADATA}${recordID}`,
            value: {
                isLoadingInitialReportActions: false,
            },
        },
    ];

    const failureData: OnyxUpdate[] = [
        {
            onyxMethod: Onyx.METHOD.MERGE,
            key: `${ONYXKEYS.COLLECTION.MEDICATION_METADATA}${recordID}`, // *** what is REPORT_METADATA?. What it does
            value: {
                isLoadingInitialReportActions: false,
            },
        },
    ];

    const parameters: OpenMedicationParams = {
        recordID,
    };

    // eslint-disable-next-line rulesdir/no-multiple-api-calls
    API.write(WRITE_COMMANDS.OPEN_MEDICATION, parameters, {optimisticData, successData, failureData});
}

function openAppointment(recordID: string) {
    if (!recordID) {
        return;
    }

    const optimisticData: OnyxUpdate[] = [
        {
            onyxMethod: Onyx.METHOD.MERGE,
            key: `${ONYXKEYS.COLLECTION.APPOINTMENT}${recordID}`,
            value: {},
        }
    ];

    const successData: OnyxUpdate[] = [
        {
            onyxMethod: Onyx.METHOD.MERGE,
            key: `${ONYXKEYS.COLLECTION.APPOINTMENT}${recordID}`,
            value: {
                // TODO check this value if don't get data
            },
        },
        {
            onyxMethod: Onyx.METHOD.MERGE,
            key: `${ONYXKEYS.COLLECTION.MEDICATION_METADATA}${recordID}`,
            value: {
                isLoadingInitialReportActions: false,
            },
        },
    ];

    const failureData: OnyxUpdate[] = [
        {
            onyxMethod: Onyx.METHOD.MERGE,
            key: `${ONYXKEYS.COLLECTION.MEDICATION_METADATA}${recordID}`, // *** what is REPORT_METADATA?. What it does
            value: {
                isLoadingInitialReportActions: false,
            },
        },
    ];

    const parameters: TrackAppointmentParams = {
        recordID,
    };

    // eslint-disable-next-line rulesdir/no-multiple-api-calls
    API.write(WRITE_COMMANDS.OPEN_APPOINTMENT, parameters, {optimisticData, successData, failureData});
}

function openDocument(documentID: string) {
    if (!documentID) {
        return;
    }

    const optimisticData: OnyxUpdate[] = [
        {
            onyxMethod: Onyx.METHOD.MERGE,
            key: `${ONYXKEYS.COLLECTION.REPORT_METADATA}${documentID}`,
            value: {
                isLoadingInitialReportActions: true,
                isLoadingOlderReportActions: false,
                hasLoadingOlderReportActionsError: false,
                isLoadingNewerReportActions: false,
                hasLoadingNewerReportActionsError: false,
                lastVisitTime: DateUtils.getDBTime(),
            },
        },
    ];

    const successData: OnyxUpdate[] = [
        {
            onyxMethod: Onyx.METHOD.MERGE,
            key: `${ONYXKEYS.COLLECTION.DOCUMENT}${documentID}`,
            value: {
                // TODO check this value if don't get data
            },
        },
        {
            onyxMethod: Onyx.METHOD.MERGE,
            key: `${ONYXKEYS.COLLECTION.MEDICATION_METADATA}${documentID}`,
            value: {
                isLoadingInitialReportActions: false,
            },
        },
    ];

    const failureData: OnyxUpdate[] = [
    ];

    const parameters: TrackDocumentParams = {
        documentID,
    };

    // eslint-disable-next-line rulesdir/no-multiple-api-calls
    API.write(WRITE_COMMANDS.OPEN_DOCUMENT, parameters, {optimisticData, successData, failureData});
}

function openDoctor(doctorID: string) {
    if (!doctorID) {
        return;
    }
    

    const optimisticData: OnyxUpdate[] = [
        // {
        //     onyxMethod: Onyx.METHOD.MERGE,
        //     key: `${ONYXKEYS.COLLECTION.REPORT}${reportID}`,
        //     value: optimisticReport,
        // },
        // {
        //     onyxMethod: Onyx.METHOD.MERGE,
        //     key: `${ONYXKEYS.COLLECTION.REPORT_METADATA}${reportID}`,
        //     value: {
        //         isLoadingInitialReportActions: true,
        //         isLoadingOlderReportActions: false,
        //         hasLoadingOlderReportActionsError: false,
        //         isLoadingNewerReportActions: false,
        //         hasLoadingNewerReportActionsError: false,
        //         lastVisitTime: DateUtils.getDBTime(),
        //     },
        // },
    ];

    const successData: OnyxUpdate[] = [
        {
            onyxMethod: Onyx.METHOD.MERGE,
            key: `${ONYXKEYS.COLLECTION.DOCTOR}${doctorID}`,
            value: {
                // TODO check this value if don't get data
            },
        },
        {
            onyxMethod: Onyx.METHOD.MERGE,
            key: `${ONYXKEYS.COLLECTION.MEDICATION_METADATA}${doctorID}`,
            value: {
                isLoadingInitialReportActions: false,
            },
        },
    ];

    const failureData: OnyxUpdate[] = [
    ];

    const parameters: AddDoctorParams = {
        doctorID,
    };

    // eslint-disable-next-line rulesdir/no-multiple-api-calls
    API.write(WRITE_COMMANDS.OPEN_DOCTOR, parameters, {optimisticData, successData, failureData});
}




export {
    // Types
    type HealthRecordRequestType,
    type HealthRecordRequestData,
    
    startHealthRecordRequest,
    // Core health record functions
    saveHealthRecordRequest,
    initHealthRecordRequest,
    clearHealthRecordRequest,
    
    // Medication-related functions
    updateMedicationNote,
    updateMedicationName,
    updateMedicationDate,
    updateMedicationChatRoom,
    updateMedicationDocument,
    updateMedicationReminderOn,
    updateMedicationFrequency,
    updateMedicationDosage,
    replaceMedicationImage,
    deleteMedication,
    
    // Open functions
    openMedication,
    openAppointment,
    openDocument,
    openDoctor
};

