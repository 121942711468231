import Onyx from 'react-native-onyx';
import ONYXKEYS from '@src/ONYXKEYS';
import type {MedicationDetails} from '@src/types/onyx';
import type {Receipt} from '@src/types/onyx/Transaction';

import * as PersonalDetailsUtils from '@libs/PersonalDetailsUtils';
import type {ReportCategory} from '@src/types/onyx/PersonalDetails';
import * as ReportUtils from '@libs/ReportUtils';

import {useOnyx, withOnyx} from 'react-native-onyx';

import type * as OnyxTypes from '@src/types/onyx';
import type {OnyxEntry} from 'react-native-onyx';

import CONST from '@src/CONST';
import DateUtils from '@libs/DateUtils';

// Generic function to update medication details
function updateMedicationDetails(updates: Partial<MedicationDetails>) {

    console.log ('[UPDATE MEDICATION DATE] updates', updates);
    Onyx.merge(ONYXKEYS.MEDICATION_DETAILS, updates);
}

// Individual functions to update specific fields
function setMedicationName(medicationName: string) {
    updateMedicationDetails({medicationName});
}

function setMedicationNote(medicationNote: string) {
    updateMedicationDetails({medicationNote});
}

function setMedicationType(medicationType: string) {
    updateMedicationDetails({medicationType});
}

function setMedicationFrequency(medicationFrequency: string) {
    updateMedicationDetails({medicationFrequency});
}

function setRRRule(rrRule: string) {
    updateMedicationDetails({rrRule});
}

function setMedicationStartDate(medicationStartDate: string) {
    updateMedicationDetails({medicationStartDate});
}

function setMedicationEndDate(medicationEndDate: string) {
    updateMedicationDetails({medicationEndDate});
}

function setMedicationSchedules(medicationSchedules: string[]) {
    updateMedicationDetails({medicationSchedules});
}

function setMedicationTime(medicationTime: string) {
    updateMedicationDetails({medicationTime});
}

function setMedicationReportIDs(reportID: string[]) {
    updateMedicationDetails({reportID});
}

function setMedicationReportName(reportName: string) {
    updateMedicationDetails({reportName});
}

function setReminderOn(reminderOn: boolean) {
    updateMedicationDetails({medicationReminderOn: reminderOn});
}

function setMedicationForm(medicationForm: string) {
    updateMedicationDetails({medicationForm});
}

function setMedicationDose(medicationDose: string) {
    updateMedicationDetails({medicationDose});
}

function setMedicationUnit(medicationUnit: string) {
    updateMedicationDetails({medicationUnit});
}

function setMedicationReceipt(receipt: Receipt) {
    updateMedicationDetails({document: receipt});
}

function setMedicationDetails(medicationDetails: MedicationDetails) {
    // Create a serializable copy of the medication details
    const serializableMedicationDetails = {...medicationDetails};
    
    // Handle document/receipt if present
    if (medicationDetails.document) {
        // Store only the essential receipt information
        serializableMedicationDetails.document = {
            name: medicationDetails.document.name,
            type: medicationDetails.document.type,
            size: medicationDetails.document.size,
            uri: medicationDetails.document.uri,
            // Add any other needed metadata fields, but avoid storing the actual file data
        };
    }

    Onyx.set(ONYXKEYS.MEDICATION_DETAILS, serializableMedicationDetails);
}
function clearMedicationDetails() {
    Onyx.set(ONYXKEYS.MEDICATION_DETAILS, null);
}


const getMedicationDetailsDefaultValues = async () => {
    const medicationReportID = PersonalDetailsUtils.getDefaultReportIDByCategory('Medication');
    console.log ("Setting default values for medication")
    if (!medicationReportID) {
        console.warn('No default medication report found.');
        // return;
    }

    // Get report data first
    const medicationReport = await new Promise<OnyxEntry<OnyxTypes.Report>>((resolve) => {
        let hasResolved = false;
        
        Onyx.connect({
            key: `${ONYXKEYS.COLLECTION.REPORT}${medicationReportID}`,
            callback: (report) => {
                if (!hasResolved) {
                    hasResolved = true;
                    resolve(report);
                }
            },
        });
    });
    const medicationReportName = ReportUtils.getReportName(medicationReport) || '';

    // Set all medication details at once
    const updates = {
        // medicationStartDate: new Date().toISOString().split('T').at(0),
        // medicationEndDate: (() => {
        //     const oneYearFromNow = new Date();
        //     oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1);
        //     return oneYearFromNow.toISOString().split('T').at(0);
        // })(),

        medicationStartDate: new Date().toISOString(),
        medicationEndDate: (() => {
            const oneYearFromNow = new Date();
            oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1);
            return oneYearFromNow.toISOString();
        })(),


        // medicationTime: DateUtils.getOneHourFromNow(),
        medicationFrequency: CONST.MEDICATION_FREQUENCY.DAILY.key,
        medicationReminderOn: false,
        medicationForm: 'tabletsAndCapsules',
        medicationDose: '1',
        medicationUnit: 'tablet(s)',
        reportID: medicationReportID ? [medicationReportID] : undefined,
        reportName: medicationReportName,
    };

    // Use set instead of merge to ensure we have a clean state
    await Onyx.set(ONYXKEYS.MEDICATION_DETAILS, updates);
    return updates;
};





// Named exports
export {
    setMedicationName,
    setMedicationNote,
    setMedicationType,
    setMedicationFrequency,
    setRRRule,
    setMedicationStartDate,
    setMedicationEndDate,
    setMedicationSchedules,
    setReminderOn,
    setMedicationForm,
    setMedicationDose,
    setMedicationUnit,
    setMedicationReceipt,
    setMedicationTime,
    setMedicationReportIDs,
    setMedicationReportName,
    setMedicationDetails,
    clearMedicationDetails,
    getMedicationDetailsDefaultValues,
};
