import React, {memo} from 'react';
import {View} from 'react-native';
import type {StyleProp, ViewStyle} from 'react-native';
import Icon from '@components/Icon';
import * as Expensicons from '@components/Icon/Expensicons';
import useTheme from '@hooks/useTheme';
import useThemeStyles from '@hooks/useThemeStyles';
import variables from '@styles/variables';
import DateInfoCell from './DateInfoCell';

type MedicationItemHeaderNarrowProps = {
    startDate: string;
    endDate: string;
    containerStyle?: StyleProp<ViewStyle>;
};

function MedicationItemHeaderNarrow({containerStyle, startDate, endDate}: MedicationItemHeaderNarrowProps) {
    const styles = useThemeStyles();

    const theme = useTheme();

    // It might happen that we are missing display names for `From` or `To`, we only display arrow icon if both names exist
    const shouldDisplayArrowIcon = true;

    return (
        <View style={[styles.flex1, styles.flexRow, styles.alignItemsCenter, styles.justifyContentBetween, styles.mb3, styles.gap2, containerStyle]}>
            <View style={[styles.flexRow, styles.alignItemsCenter, styles.gap2, styles.flex1]}>
                <View style={[styles.mw50]}>
                    <DateInfoCell date={startDate} />
                </View>
                {shouldDisplayArrowIcon && (
                    <Icon
                        src={Expensicons.ArrowRightLong}
                        width={variables.iconSizeXXSmall}
                        height={variables.iconSizeXXSmall}
                        fill={theme.icon}
                    />
                )}
                <View style={[styles.flex1, styles.mw50]}>
                    <DateInfoCell date={endDate} />
                </View>
            </View>
        </View>
    );
}

export default memo(MedicationItemHeaderNarrow);
