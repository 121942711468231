import * as API from '@libs/API';
import type {TrackDocumentParams} from '@libs/API/parameters';
import {WRITE_COMMANDS} from '@libs/API/types';
import Navigation from '@libs/Navigation/Navigation';
import * as ReportUtils from '@libs/ReportUtils';
import * as SearchUtils from '@libs/SearchUtils';
import CONST from '@src/CONST';
import ROUTES from '@src/ROUTES';
import type {Route} from '@src/ROUTES';

import type {AppointmentDetails, DocumentDetails, MedicationDetails} from '@src/types/onyx';
import type {Receipt} from '@src/types/onyx/Transaction';
// import {getMedicationDefaultValues} from './medication';
import * as NumberUtils from '@libs/NumberUtils';

async function saveDocument(documentDetail: DocumentDetails, receipt: Receipt | undefined) {
    // Optimistic update to set loading state

    // Convert response to a blob

    const x: TrackDocumentParams = {
        ...documentDetail,
        reportID: documentDetail.reportID ?? '',
        recordID: documentDetail.recordID ?? '',
        documentType: "document",


        // documentCategory: 'LAB_TEST',
        // documentDate: '2024-11-11',
        // documentName: 'doc123',
        // documentCategory:'LAB_TEST',

        document: receipt,
    };

    // API call with write parameters
    API.write(WRITE_COMMANDS.SCAN_DOCUMENT, x, {
        // optimisticData,
        // successData,
        // failureData,
    });

    // const query = SearchUtils.buildCannedSearchQuery({type: CONST.SEARCH.DATA_TYPES.DOCUMENTS, status: CONST.SEARCH.STATUS.CHAT.ALL});

    // Navigation.dismissModal(activeReportID);
    // Navigation.navigate(ROUTES.SEARCH_CENTRAL_PANE.getRoute({query}));


    const activeReportID = x.reportID?.[0] ?? '';
    if (activeReportID) {
        Navigation.dismissModal(activeReportID); 
        Navigation.navigate(ROUTES.REPORT_WITH_ID.getRoute(activeReportID));
    } else {
        Navigation.navigate(ROUTES.SEARCH_CENTRAL_PANE.route as Route);
    }



}

export {saveDocument};
