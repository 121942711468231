import Onyx from 'react-native-onyx';
import ONYXKEYS from '@src/ONYXKEYS';
import type {AppointmentDetails} from '@src/types/onyx';
import type {Receipt} from '@src/types/onyx/Transaction';
import CONST from '@src/CONST';
import DateUtils from '@libs/DateUtils';


import * as PersonalDetailsUtils from '@libs/PersonalDetailsUtils';
import type {ReportCategory} from '@src/types/onyx/PersonalDetails';
import * as ReportUtils from '@libs/ReportUtils';

import {useOnyx, withOnyx} from 'react-native-onyx';

import type * as OnyxTypes from '@src/types/onyx';
import type {OnyxEntry} from 'react-native-onyx';

import type {TranslationPaths} from '@src/languages/types';


// Generic function to update appointment details
function updateAppointmentDetails(updates: Partial<AppointmentDetails>) {
    Onyx.merge(ONYXKEYS.APPOINTMENT_DETAILS, updates);
}

// Individual functions to update specific fields
function setDoctorID(doctorID: string) {
    updateAppointmentDetails({doctorID});
}

function setDoctorName(doctorName: string) {
    updateAppointmentDetails({doctorName});
}

function setDoctorSpecialty(doctorSpecialty: string) {
    updateAppointmentDetails({doctorSpecialty});
}

function setrecordID(recordID: string) {
    updateAppointmentDetails({recordID});
}

function setAppointmentStartDate(appointmentStartDate: string) {
    updateAppointmentDetails({appointmentStartDate});
}

function setAppointmentNotifyBefore(appointmentNotifyBefore: keyof typeof CONST.NOTIFICATION_TIMING) {
    updateAppointmentDetails({appointmentNotifyBefore});
}

function setAppointmentTime(appointmentTime: string) {
    updateAppointmentDetails({appointmentTime});
}

function setAppointmentReminderOn(appointmentReminderOn: boolean) {
    updateAppointmentDetails({appointmentReminderOn});
}

function setAppointmentReportName(reportName: string) {
    console.log ('SET APPOINTMENT REPORT NAME', reportName);
    updateAppointmentDetails({reportName});
}

function setAppointmentReportIDs(reportID: string[]) {
    updateAppointmentDetails({reportID});
}

function setAppointmentNote(appointmentNote: string) {
    updateAppointmentDetails({appointmentNote});
}

function setDocument(document: Receipt) {
    updateAppointmentDetails({document});
}

function setDocumentState(documentState: string) {
    updateAppointmentDetails({documentState});
}

function setAppointmentDetails(appointmentDetails: AppointmentDetails) {
    Onyx.set(ONYXKEYS.APPOINTMENT_DETAILS, appointmentDetails);
}


function clearAppointmentDetails() {
    Onyx.set(ONYXKEYS.APPOINTMENT_DETAILS, null);
}


const getAppointmentDetailsDefaultValues = async () => {
    const appointmentReportID = PersonalDetailsUtils.getDefaultReportIDByCategory('Appointment');
    console.log ("Setting default values for appointment")
    if (!appointmentReportID) {
        console.warn('No default appointment report found.');
        // return;
    }

    // Get report data first
    const appointmentReport = await new Promise<OnyxEntry<OnyxTypes.Report>>((resolve) => {
        let hasResolved = false;
        
        Onyx.connect({
            key: `${ONYXKEYS.COLLECTION.REPORT}${appointmentReportID}`,
            callback: (report) => {
                if (!hasResolved) {
                    hasResolved = true;
                    resolve(report);
                }
            },
        });
    });
    const appointmentReportName = ReportUtils.getReportName(appointmentReport) || '';

    // Set all medication details at once
    const updates = {
        appointmentStartDate: new Date().toISOString(),
        appointmentNotifyBefore: CONST.NOTIFICATION_TIMING.ONE_HOUR,
        appointmentReminderOn: false,
        reportID: appointmentReportID ? [appointmentReportID] : undefined,
        reportName: appointmentReportName,
    };

    // Use set instead of merge to ensure we have a clean state
    await Onyx.set(ONYXKEYS.APPOINTMENT_DETAILS, updates);
    return updates;
};


/**
 * Get the readable notification timing text for a given appointment frequency
 * @param frequency - The appointment frequency value from NOTIFICATION_TIMING
 * @param translate - The translate function from useLocalize
 * @returns The translated human-readable notification timing text
 */
 function getReadableNotificationTiming(frequency: string | undefined, translate: (key: TranslationPaths) => string): string {
    if (!frequency) {
        return 'Unknown';
    }

    const notifyOption = Object.entries(CONST.APPOINTMENT_NOTIFY_BEFORE_OPTIONS)
        .find(([key]) => CONST.NOTIFICATION_TIMING[key as keyof typeof CONST.NOTIFICATION_TIMING] === frequency)?.[1];
    
    return notifyOption 
        ? translate(notifyOption.translationKey as TranslationPaths)
        : frequency;
}








// Named exports
export {
    setDoctorID,
    setDoctorName,
    setDoctorSpecialty,
    setrecordID,
    setAppointmentStartDate,
    setAppointmentNotifyBefore,
    setAppointmentTime,
    setAppointmentReminderOn,
    setAppointmentReportIDs,
    setAppointmentReportName,
    setAppointmentNote,
    setDocument,
    setDocumentState,
    setAppointmentDetails,
    getAppointmentDetailsDefaultValues,
    clearAppointmentDetails,
    getReadableNotificationTiming,
};
