import {Str} from 'expensify-common';
import React from 'react';
import type {StyleProp, ViewStyle} from 'react-native';
import {View} from 'react-native';
import * as Expensicons from '@components/Icon/Expensicons';
import ReceiptImage from '@components/ReceiptImage';
import type {MedicationListItemtype, TransactionListItemType} from '@components/SelectionList/types';
import TextWithTooltip from '@components/TextWithTooltip';
import useStyleUtils from '@hooks/useStyleUtils';
import useTheme from '@hooks/useTheme';
import useThemeStyles from '@hooks/useThemeStyles';
import {getFileName} from '@libs/fileDownload/FileUtils';
import {getThumbnailAndImageURIs, getThumbnailAndImageURIs1, getThumbnailAndImageURIs2} from '@libs/ReceiptUtils';
import tryResolveUrlFromApiRoot from '@libs/tryResolveUrlFromApiRoot';
import variables from '@styles/variables';
import type {MedicationDetails} from '@src/types/onyx';
import MedicationItemHeaderNarrow from './MedicationItemHeaderNarrow';
import DateUtils from '@libs/DateUtils';

type CellProps = {
    // eslint-disable-next-line react/no-unused-prop-types
    showTooltip: boolean;
    // eslint-disable-next-line react/no-unused-prop-types
    isLargeScreenWidth: boolean;
};

type TransactionCellProps = {
    transactionItem: MedicationListItemtype;
} & CellProps;

type MedicationListItemRowProps = {
    item: MedicationDetails;

    containerStyle?: StyleProp<ViewStyle>;
};

function ReceiptCell({transactionItem}: TransactionCellProps) {
    const theme = useTheme();
    const styles = useThemeStyles();
    const StyleUtils = useStyleUtils();

    const backgroundStyles = transactionItem.isSelected ? StyleUtils.getBackgroundColorStyle(theme.buttonHoveredBG) : StyleUtils.getBackgroundColorStyle(theme.border);

    let source = transactionItem?.document?.source ?? '';
    console.log('[MEDICATION LIST ITEM ROW] source', source);


    if (source) {
        const filename = getFileName(source);
        //@ts-ignore
        const receiptURIs = getThumbnailAndImageURIs1(transactionItem, null, filename);


        const isReceiptPDF = Str.isPDF(filename);

        source = tryResolveUrlFromApiRoot(isReceiptPDF && !receiptURIs.isLocalFile ? receiptURIs.thumbnail ?? '' : receiptURIs.image ?? '');
    }

    return (
        <View
            style={[
                StyleUtils.getWidthAndHeightStyle(variables.h36, variables.w40),
                StyleUtils.getBorderRadiusStyle(variables.componentBorderRadiusSmall),
                styles.overflowHidden,
                backgroundStyles,
            ]}
        >
            <ReceiptImage
                source={source}
                isEReceipt={transactionItem.hasEReceipt}
                transactionID={transactionItem.transactionID}
                // shouldUseThumbnailImage={!transactionItem?.receipt?.source}
                shouldUseThumbnailImage={!transactionItem?.document?.source}
                isAuthTokenRequired
                fallbackIcon={Expensicons.Receipt}
                fallbackIconSize={20}
                fallbackIconColor={theme.icon}
                fallbackIconBackground={transactionItem.isSelected ? theme.buttonHoveredBG : undefined}
                iconSize="x-small"
            />
        </View>
    );
}

function DateCell({date}: {date: string}) {
    const styles = useThemeStyles();

    return (
        <TextWithTooltip
            shouldShowTooltip={false}
            text={date}
            style={[styles.lineHeightLarge, styles.pre, styles.justifyContentCenter, false ? undefined : [styles.textMicro, styles.textSupporting]]}
        />
    );
}

function MedicationCell({name}: {name: string}) {
    const styles = useThemeStyles();

    return (
        <TextWithTooltip
            shouldShowTooltip={false}
            text={name}
            style={[true ? styles.lineHeightLarge : styles.lh20, styles.pre, styles.justifyContentCenter]}
        />
    );
}

function TimeCell({time}: {time: string}) {
    const styles = useThemeStyles();

    return (
        <TextWithTooltip
            shouldShowTooltip={false}
            text={time}
            style={[styles.optionDisplayName, styles.justifyContentCenter, styles.textAlignRight]}
        />
    );
}

function DescriptionCell({description}: {description: string}) {
    const styles = useThemeStyles();
    return (
        <TextWithTooltip
            shouldShowTooltip
            text={description}
            style={[
                styles.optionDisplayName,
                styles.label,
                styles.pre,
                styles.justifyContentCenter,
                styles.textMicro,
                styles.textSupporting,
                styles.flexShrink1,
                styles.textMicro,
                styles.mnh0,
            ]}
        />
    );
}

function MedicationListItemRow({item, containerStyle}: MedicationListItemRowProps) {
    const styles = useThemeStyles();

    if (true) {
        return (
            <View style={containerStyle}>
                <MedicationItemHeaderNarrow
                    endDate={DateUtils.formatDate(item.medicationEndDate ?? '')}
                    startDate={DateUtils.formatDate(item.medicationStartDate ?? '')}
                />

                <View style={[styles.flexRow, styles.justifyContentBetween, styles.alignItemsCenter, styles.gap3]}>
                    <ReceiptCell
                       
                        transactionItem={item ?? {}}
                        isLargeScreenWidth={false}
                        showTooltip={false}
                    />
                    <View style={[styles.flex2, true && styles.justifyContentCenter, styles.gap1]}>
                        <MedicationCell name={item.medicationName ?? ''} />

                        <View style={[styles.flexRow, styles.flex1, styles.alignItemsEnd]}>
                            <DescriptionCell description={item.medicationNote ?? ''} />
                        </View>
                    </View>
                    <View style={[styles.alignItemsEnd, styles.flex1, styles.gap1, styles.justifyContentBetween]}>
                        <TimeCell time={DateUtils.extractTime12Hour(item.medicationStartDate ?? '')} />
                        <View style={[styles.flexRow, styles.gap1, styles.justifyContentCenter, styles.alignItemsCenter]}>
                            <DateCell date={item?.medicationFrequency ?? ''} />
                        </View>
                    </View>
                </View>
            </View>
        );
    }

    return (
        <View style={containerStyle}>
            <MedicationItemHeaderNarrow
                endDate="2023 Mar 12"
                startDate="2024 Aug 15"
            />
        </View>
    );
}

MedicationListItemRow.displayName = 'MedicationListItemRow';

export default MedicationListItemRow;
