// import {LangfuseWeb} from 'langfuse';
import React, {useRef} from 'react';
import {View} from 'react-native';
import {withOnyx} from 'react-native-onyx';
import type {Emoji} from '@assets/emojis/types';
import BaseMiniContextMenuItem from '@components/BaseMiniContextMenuItem';
import Icon from '@components/Icon';
import * as Expensicons from '@components/Icon/Expensicons';
import Text from '@components/Text';
import useLocalize from '@hooks/useLocalize';
import useStyleUtils from '@hooks/useStyleUtils';
import useThemeStyles from '@hooks/useThemeStyles';
import * as EmojiUtils from '@libs/EmojiUtils';
import getButtonState from '@libs/getButtonState';
import variables from '@styles/variables';
import * as EmojiPickerAction from '@userActions/EmojiPickerAction';
import * as Session from '@userActions/Session';
import CONST from '@src/CONST';
import ONYXKEYS from '@src/ONYXKEYS';
import type {BaseQuickEmojiReactionsOnyxProps, BaseQuickEmojiReactionsProps} from './QuickEmojiReactions/types';

type MiniQuickEmojiReactionsProps = BaseQuickEmojiReactionsProps & {
    /**
     * Will be called when the user closed the emoji picker
     * without selecting an emoji.
     */
    onEmojiPickerClosed?: () => void;
};

/**
 * Shows the four common quick reactions and a
 * emoji picker icon button. This is used for the mini
 * context menu which we just show on web, when hovering
 * a message.
 */
function MiniQuickEmojiReactions({
    reportAction,
    onEmojiSelected,
    preferredLocale = CONST.LOCALES.DEFAULT,
    preferredSkinTone = CONST.EMOJI_DEFAULT_SKIN_TONE,
    emojiReactions = {},
    onPressOpenPicker = () => {},
    onEmojiPickerClosed = () => {},
}: MiniQuickEmojiReactionsProps) {
    const styles = useThemeStyles();
    const StyleUtils = useStyleUtils();
    const ref = useRef<View>(null);
    const {translate} = useLocalize();

    // const langfuseWeb = new LangfuseWeb({
    //     publicKey: 'pk-lf-631ca2a5-5aa9-4078-b1d5-648e261714af',
    //     baseUrl: 'https://us.cloud.langfuse.com', // Replace with your public key
    // });

    // const handleLangfuseFeedback = async (emoji: Emoji) => {
    //     const value = emoji.name === '+1' ? 1 : 0;
    //     try {
    //         await langfuseWeb.score({
    //             id: `feedback-${reportAction.reportActionID}`, // unique ID for this feedback
    //             traceId: reportAction.reportActionID,
    //             name: 'helpfulness',
    //             value: value,
    //             dataType: 'BOOLEAN', // Specify boolean data type
    //             comment: value === 1 ? 'Helpful response' : 'Unhelpful response', // Optional comment
    //         });

    //         // Still call the original handler to update UI
    //         onEmojiSelected(emoji, emojiReactions);
    //     } catch (error) {
    //         console.error('Failed to send feedback to Langfuse:', error);
    //     }
    // };

    const openEmojiPicker = () => {
        onPressOpenPicker();
        EmojiPickerAction.showEmojiPicker(
            onEmojiPickerClosed,
            (emojiCode, emojiObject) => {
                onEmojiSelected(emojiObject, emojiReactions);
            },
            ref,
            undefined,
            () => {},
            reportAction.reportActionID,
        );
    };

    return (
        <View style={styles.flexRow}>
            {CONST.QUICK_REACTIONS.slice(0, 2).map((emoji: Emoji) => (
                <BaseMiniContextMenuItem
                    key={emoji.name}
                    isDelayButtonStateComplete={false}
                    tooltipText={`:${EmojiUtils.getLocalizedEmojiName(emoji.name, preferredLocale)}:`}
                    // onPress={Session.checkIfActionIsAllowed(() => onEmojiSelected(emoji, emojiReactions))}
                    // onPress={Session.checkIfActionIsAllowed(() => handleLangfuseFeedback(emoji))}
                    onPress={() => console.log('')}
                >
                    <Text
                        style={[styles.miniQuickEmojiReactionText, styles.userSelectNone]}
                        dataSet={{[CONST.SELECTION_SCRAPER_HIDDEN_ELEMENT]: true}}
                    >
                        {EmojiUtils.getPreferredEmojiCode(emoji, preferredSkinTone)}
                    </Text>
                </BaseMiniContextMenuItem>
            ))}
            {/* <BaseMiniContextMenuItem
                ref={ref}
                onPress={Session.checkIfActionIsAllowed(() => {
                    if (!EmojiPickerAction.emojiPickerRef.current?.isEmojiPickerVisible) {
                        openEmojiPicker();
                    } else {
                        EmojiPickerAction.emojiPickerRef.current?.hideEmojiPicker();
                    }
                })}
                isDelayButtonStateComplete={false}
                tooltipText={translate('emojiReactions.addReactionTooltip')}
            >
                {({hovered, pressed}) => (
                    <Icon
                        width={variables.iconSizeMedium}
                        height={variables.iconSizeMedium}
                        src={Expensicons.AddReaction}
                        fill={StyleUtils.getIconFillColor(getButtonState(hovered, pressed, false))}
                    />
                )}
            </BaseMiniContextMenuItem> */}
        </View>
    );
}

MiniQuickEmojiReactions.displayName = 'MiniQuickEmojiReactions';

export default withOnyx<MiniQuickEmojiReactionsProps, BaseQuickEmojiReactionsOnyxProps>({
    preferredSkinTone: {
        key: ONYXKEYS.PREFERRED_EMOJI_SKIN_TONE,
    },
    emojiReactions: {
        key: ({reportActionID}) => `${ONYXKEYS.COLLECTION.REPORT_ACTIONS_REACTIONS}${reportActionID}`,
    },
    preferredLocale: {
        key: ONYXKEYS.NVP_PREFERRED_LOCALE,
    },
})(MiniQuickEmojiReactions);
