import React, {useCallback, useEffect, useMemo, useState} from 'react';
import ActiveWorkspaceContext from '@components/ActiveWorkspace/ActiveWorkspaceContext';
import CONST from '@src/CONST';
import type ChildrenProps from '@src/types/utils/ChildrenProps';

function ActiveWorkspaceContextProvider({children}: ChildrenProps) {
    const [activeWorkspaceID, updateActiveWorkspaceID] = useState<string | undefined>(undefined);

    const setActiveWorkspaceID = useCallback(
        (workspaceID: string | undefined) => {
            console.log('[ActiveWorkspaceProvider] Setting workspace ID:', {
                workspaceID,
                previousID: activeWorkspaceID,
                sessionStorageValue: sessionStorage?.getItem(CONST.SESSION_STORAGE_KEYS.ACTIVE_WORKSPACE_ID),
            });

            updateActiveWorkspaceID(workspaceID);
            if (workspaceID && sessionStorage) {
                sessionStorage?.setItem(CONST.SESSION_STORAGE_KEYS.ACTIVE_WORKSPACE_ID, workspaceID);
                console.log('[ActiveWorkspaceProvider] Updated sessionStorage:', {
                    newValue: sessionStorage?.getItem(CONST.SESSION_STORAGE_KEYS.ACTIVE_WORKSPACE_ID),
                });
            } else {
                sessionStorage?.removeItem(CONST.SESSION_STORAGE_KEYS.ACTIVE_WORKSPACE_ID);
                console.log('[ActiveWorkspaceProvider] Removed from sessionStorage');
            }
        },
        [activeWorkspaceID],
    );

    // Add effect to monitor changes
    useEffect(() => {
        console.log('[ActiveWorkspaceProvider] Workspace ID changed:', {
            activeWorkspaceID,
            sessionStorageValue: sessionStorage?.getItem(CONST.SESSION_STORAGE_KEYS.ACTIVE_WORKSPACE_ID),
        });
    }, [activeWorkspaceID]);

    return <ActiveWorkspaceContext.Provider value={{activeWorkspaceID, setActiveWorkspaceID}}>{children}</ActiveWorkspaceContext.Provider>;
}

export default ActiveWorkspaceContextProvider;
