import type {OnyxUpdate} from 'react-native-onyx';
import Onyx from 'react-native-onyx';
import * as API from '@libs/API';
import type {TrackAppointmentParams} from '@libs/API/parameters';
import {WRITE_COMMANDS} from '@libs/API/types';
import Navigation from '@libs/Navigation/Navigation';
import * as ReportUtils from '@libs/ReportUtils';
import * as SearchUtils from '@libs/SearchUtils';
import CONST from '@src/CONST';
import ONYXKEYS from '@src/ONYXKEYS';
import ROUTES from '@src/ROUTES';
import type {Route} from '@src/ROUTES';


import type {AppointmentDetails, MedicationDetails} from '@src/types/onyx';
import type {Receipt} from '@src/types/onyx/Transaction';
// import {getMedicationDefaultValues} from './medication';

async function saveAppointment(appointmentDetails: AppointmentDetails, receipt: Receipt | undefined) {
    // Optimistic update to set loading state

    // Convert response to a blob

    // const defaultMedication = getMedicationDefaultValues();

    const x: TrackAppointmentParams = {
        ...appointmentDetails,
        recordID: appointmentDetails?.recordID,
        documentState: 'OPEN', // enum value

        document: receipt,
        doctorID: appointmentDetails?.doctorID,
        appointmentStartDate: appointmentDetails?.appointmentStartDate,
        appointmentTime: appointmentDetails?.appointmentTime || '12:00 PM',
    };

    const optimisticData: OnyxUpdate[] = [
        {
            onyxMethod: Onyx.METHOD.MERGE,
            key: `${ONYXKEYS.COLLECTION.APPOINTMENT}${x.recordID}`,

            value: {
                ...x,
            },
        },
    ];

    // Successful update, clearing loading state and updating with server response
    // const successData: OnyxUpdate[] = [
    //     {
    //         onyxMethod: Onyx.METHOD.MERGE,
    //         key: `${ONYXKEYS.COLLECTION.MEDICATION}${x.recordID}`,
    //         // REMOVE  the optimistic one for preventing duplication and set the new one
    //         value: {},
    //     },
    // ];

    // Failure update, clearing loading state and marking the operation as failed
    // const failureData: OnyxUpdate[] = [
    //     {
    //         onyxMethod: Onyx.METHOD.MERGE,
    //         key: `${ONYXKEYS.COLLECTION.MEDICATION}${x.recordID}`,
    //         value: {},
    //     },
    // ];

    const successData: OnyxUpdate[] = [
        {
            onyxMethod: Onyx.METHOD.MERGE,
            key: `${ONYXKEYS.COLLECTION.MEDICATION}${x.recordID}`,
            // REMOVE  the optimistic one for preventing duplication and set the new one
            value: {},
        },
        {
            onyxMethod: Onyx.METHOD.SET,
            key: `${ONYXKEYS.APPOINTMENT_DETAILS}`,
            // REMOVE  the optimistic one for preventing duplication and set the new one
            value: {},
        },
    ];

    // Failure update, clearing loading state and marking the operation as failed
    const failureData: OnyxUpdate[] = [
        {
            onyxMethod: Onyx.METHOD.MERGE,
            key: `${ONYXKEYS.COLLECTION.MEDICATION}${x.recordID}`,
            value: {},
        },
    ];


    if (receipt && ! appointmentDetails?.doctor?.doctorID  ){

        const y = {
            recordID: appointmentDetails?.recordID,
            reportID: appointmentDetails?.reportID,
            documentType: 'appointment',
            document: receipt,
        }



        API.write(WRITE_COMMANDS.SCAN_DOCUMENT, y, {
                    optimisticData,
                    successData,
                    failureData,
                });

    }

    else{
            // API call with write parameters
        API.write(WRITE_COMMANDS.TRACK_APPOINTMENT, x, {
            optimisticData,
            successData,
            failureData,
        });

    }

  
    const query = SearchUtils.buildCannedSearchQuery({type: CONST.SEARCH.DATA_TYPES.APPOINTMENTS, status: CONST.SEARCH.STATUS.CHAT.ALL});

    // Navigation.dismissModal(activeReportID);
    // Navigation.navigate(ROUTES.SEARCH_CENTRAL_PANE.getRoute({query}));

    const activeReportID = x.reportID?.[0] ?? '';
    if (activeReportID) {
        Navigation.dismissModal(activeReportID); 
        Navigation.navigate(ROUTES.REPORT_WITH_ID.getRoute(activeReportID));
    } else {
        Navigation.navigate(ROUTES.SEARCH_CENTRAL_PANE.route as Route);
    }


}

export {saveAppointment};
