import React from 'react';
import {View} from 'react-native';
import Avatar from '@components/Avatar';
import Text from '@components/Text';
import useResponsiveLayout from '@hooks/useResponsiveLayout';
import useThemeStyles from '@hooks/useThemeStyles';
import CONST from '@src/CONST';

type UserInfoCellProps = {
    date?: string;
};

function DateInfoCell({date}: UserInfoCellProps) {
    const styles = useThemeStyles();
    const {isLargeScreenWidth} = useResponsiveLayout();

    return (
        <View style={[styles.flexRow, styles.alignItemsCenter]}>
            <Avatar
                imageStyles={[styles.alignSelfCenter]}
                size={CONST.AVATAR_SIZE.MID_SUBSCRIPT}
                type={CONST.ICON_TYPE_AVATAR}
                containerStyles={[styles.pr2]}
            />
            <Text
                numberOfLines={1}
                style={[isLargeScreenWidth ? styles.themeTextColor : [styles.textMicro, styles.textBold], styles.flexShrink1]}
            >
                {date}
            </Text>
        </View>
    );
}

DateInfoCell.displayName = 'DateInfoCell';

export default DateInfoCell;
